ion-alert {
  --max-width: 500px !important;

  .alert-wrapper {
    border: 1px solid black;
    .alert-button-group {
      flex-direction: unset;
      flex-wrap: nowrap;
      justify-content: center;

      .alert-button {
        width: 160px;
        margin-left: 8px;
        margin-right: 8px;
        text-transform: none;
        text-align: center;
        background: var(--ion-color-primary);
        color: white;
        border-radius: 6px;
        font-weight: 500 !important;
      }

      .alert-button-role-cancel {
        background: none !important;
        color: var(--ion-color-primary);
        border: 1px solid var(--ion-color-primary);
      }

      .alert-button-inner {
        justify-content: center;
      }
    }
  }

  .button-primary {
    background: var(--ion-color-primary) !important;
    color: white !important;
  }

  .button-secondary {
    background: var(--ion-color-secondary) !important;
    color: var(--ion-color-primary) !important;
  }

  .button-danger {
    background: var(--ion-color-danger) !important;
    color: white !important;
  }
}

ion-button::part(native) {
  border-radius: 50px !important;
  text-transform: none !important;
}

.button-primary::part(native) {
  background: var(--ion-color-secondary) !important;
  color: white !important;
}

ion-searchbar {
  --box-shadow: none !important;
  .searchbar-input {
    border: 1px solid black !important;
    border-radius: 6px;
  }
}

ion-segment-button {
  margin-left: 5px;
  margin-right: 5px;
}

ion-segment-button::part(native) {
  color: black;
  border-radius: 6px;
  border: 2px solid #efefef;
  text-transform: none;
}

ion-segment-button.segment-button-checked::part(native) {
  background: var(--ion-color-primary) !important;
  color: white !important;
}

ion-segment-button::part(indicator) {
  display: none;
}
