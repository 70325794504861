/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@import "./theme/styles.scss";

@import "@uag/fonts";
@import "material-symbols/outlined.css";
@import "material-symbols/rounded.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@doka-shared/common/styles/global.scss";
@import "tippy.js/dist/tippy.css";

ion-header {
  ion-toolbar {
    --background: var(--ion-color-secondary);
  }
  ion-img {
    margin-left: 10px;
  }
}

ion-menu {
  margin-top: 56px;
  z-index: 1;
  --width: 220px;
  ion-content {
    --background: #333333;
    ion-list {
      padding: 0 !important;
      box-shadow: 0px 0px 10px var(--ion-color-dark);
      ion-item {
        --background: #333333;
        --border-color: #5c5c5c;
        --min-height: 60px;
        font-size: 0.89rem;
        --color: var(--ion-color-primary-contrast);
        ion-icon {
          color: var(--ion-color-primary-contrast);
          filter: drop-shadow(1px 1px 2px var(--ion-color-dark));
        }
      }
      ion-badge {
        margin-left: 10px;
        color: var(--ion-color-light);
      }
    }
  }
}

#content {
  // height: 100%;
  width: 100%;
  padding-left: 250px !important;
  display: flex;
  flex-direction: row;
  margin-top: 100px !important;
  transition: 500ms;
}

.contentLoaded {
  opacity: 1 !important;
  margin-top: 0 !important;
}

ion-content {
  /* --background: url(assets/imgs/background.png) center center / cover no-repeat; */
  --background: white;
}

.dashboardCard ion-card-header {
  background: var(--ion-color-primary);
  color: white;
  margin-bottom: 20px;
}

ion-fab-button {
  --background: var(--ion-color-primary, #3880ff);
  --color: white;
}

.main-content {
  margin-left: 250px !important;
  margin-top: 106px !important;
}

.main-content-inner {
  margin-left: 250px !important;
  margin-top: 46px !important;
}

.column-modal-returnColumns .modal-wrapper {
  height: 621px;
}

.column-modal-returnItemColumns .modal-wrapper {
  height: 1146px;
}

.column-modal-pickingColumns .modal-wrapper {
  height: 669px;
}

.column-modal-pickingItemColumns .modal-wrapper {
  height: 524px;
}

.itemOverview::part(content) {
  height: 90vh;
  width: 80vw;
}

.largeModal::part(content) {
  height: 90vh;
  width: 80vw;
}

.mediumModal::part(content) {
  height: 60vh;
  width: 50vw;
}

@media only screen and (max-width: 600px) {
  #content {
    padding: 0;
  }
}

.menuClosed {
  margin-left: 100px !important;
}

.articleImage::part(content) {
  width: 40vw;
  height: 40vw;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  -moz-transform: translateX(-50%) translateY(-50%) !important;
  -webkit-transform: translateX(-50%) translateY(-50%) !important;
  transform: translateX(-50%) translateY(-50%) !important;
}

.leftScrollIndicator {
  transition: 0.5s;
  position: fixed;
  height: 100%;
  width: 50px;
  z-index: 999;
  margin-right: 15px;
  background: linear-gradient(
    90deg,
    rgba(176, 176, 176, 1) -14%,
    rgba(176, 176, 176, 0) 100%
  );
  ion-icon {
    display: inline;
  }
}

.rightScrollIndicator {
  position: fixed;
  right: 0;
  height: 100%;
  width: 50px;
  z-index: 999;
  background: linear-gradient(
    90deg,
    rgba(176, 176, 176, 0) 0%,
    rgba(176, 176, 176, 1) 140%
  );
  ion-icon {
    display: inline;
  }
}

ion-button {
  text-transform: none;
  --border-radius: 100px;
}

ion-modal::part(content) {
  border-radius: 10px;
  border: 1px solid black;
}

.tableGrid ion-row {
  border-radius: 6px;
}

.itemWrapper ion-item {
  border-radius: 6px;
}

ion-searchbar {
  --border-radius: 6px !important;
}

.dummDumm {
  border-radius: 6px;
}

.itemAmount ion-input {
  --border-radius: 40px;
  border-radius: 40px;
}

.columnDisplayNone {
  //display: none !important;
  visibility: hidden;
  padding: 0px !important;
  margin: 0px;
  border: 0px;
  min-width: 0px;
  max-width: 0px;
  min-height: 0px;
  max-height: 0px;
}

.contextMenu {
  position: absolute;
  width: 320px;
  box-shadow: 1px 1px 3px black;
  top: -999px;
  left: -999px;
  z-index: 10;
  ion-list {
    margin: 0;
    padding: 0;
    ion-item {
      margin: 0;
    }
  }
}

.contextMenuShow {
  display: block !important;
}

.screenSaverContainer {
  width: 100%;
  height: 100%;

  ion-img {
    width: 153px;
    height: 69px;
    position: absolute;
    background: var(--ion-color-secondary);
  }
}

.screenSaverActivateButton {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

.screenSaverOpacity {
  opacity: 0.2;
  pointer-events: none;
}

.screenSaverInvisible {
  visibility: hidden;
}

.backButton {
  transition: 0.5s;
}

app-date-picker {
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

ion-select {
  width: 100%;
  text-align: center;
}

.infoBar {
  position: fixed;
  width: 100%;
  top: 56px;
  height: 50px;
  padding-left: 100px;
  background-color: white;
  z-index: 1000;
  display: flex;
  transition: 0.5s;
  font-weight: bold;

  .flex {
    flex-grow: 1;
  }

  .infoColumn {
    padding: 10px;
    height: 50px;
    box-shadow: inset 1px 0px #000, 0px 0px #000;
    vertical-align: middle;
    display: flex;
    white-space: pre;

    div {
      padding-left: 10px;
      line-height: 30px;
      float: left;
    }

    p {
      white-space: pre;
      margin: 0;
      line-height: 30px;
    }

    a {
      white-space: pre;
      line-height: 30px;
    }
    input {
      height: 30px;
      padding-left: 10px;
      width: 100%;
    }
  }
}

.tableWrapper {
  width: 100%;
  transition: 0.5s;
  padding-bottom: 75px;

  table {
    position: relative;
    width: 100%;
    overflow-y: scroll;
    background: white;
    user-select: none;
    font-size: 0.8rem;
    thead {
      background: white;
      position: sticky;
      top: 0;
      z-index: 3;
      box-shadow: inset 1px 1px #000, 1px 1px #000;
      th {
        padding: 10px;
        font-weight: 800;
        box-shadow: inset 1px 1px #000, 1px 1px #000;
      }
    }
    td {
      box-shadow: inset 1px 1px #000, 1px 1px #000;
      padding: 10px;
      ion-checkbox {
        z-index: 0;
      }
    }

    .headerBar {
      cursor: pointer;
      .headerText {
        width: 80%;
        float: left;
      }
      .headerSort {
        width: 20%;
        height: 100%;
        float: right;
        display: flex;
        ion-icon {
          height: 100%;
        }
      }
    }

    .searchBar {
      text-align: left;

      input {
        width: 100%;
      }
    }

    .greyRow {
      background: lightgrey;
    }

    .markedRow {
      --background: var(--ion-color-secondary) !important;
      background: var(--ion-color-secondary) !important;
    }

    .alignCenter {
      width: 100%;
      text-align: center;
    }

    .divInput {
      padding: 5px;
    }

    .inputError {
      outline: 2px solid var(--ion-color-danger);
      border-radius: 5px;
    }
  }
}

.tableWrapperPage {
  height: 100%;
  padding-left: 100px;
  padding-top: 56px;
  overflow: auto;
}

.tableWrapperOpen {
  padding-left: 250px;
}

.tableWrapperWithHeader {
  padding-top: 106px;
}

.toolbarButton {
  height: auto;
  width: auto;
  position: fixed;
  right: 20px;
  bottom: 5px;
  z-index: 1;

  .statusIcon {
    margin: 0;
    visibility: hidden;
  }
  .statusIconActive {
    visibility: visible;
  }
}

.primaryButton {
  height: auto;
  width: auto;
  position: fixed !important;
  left: 100px;
  margin-left: 20px;
  bottom: 5px;
  z-index: 1;
  transition: 0.5s;
}

.primaryToolbarButton {
  height: auto;
  width: auto;
  position: fixed !important;
  left: 100px;
  margin-left: 10px;
  bottom: 10px;
  z-index: 1;
  transition: 0.5s;
}

.newToolbarButton {
  height: auto;
  width: auto !important;
  position: fixed !important;
  right: 20px;
  bottom: 10px;
  z-index: 1;

  .statusIcon {
    margin: 0;
    visibility: hidden;
  }
  .statusIconActive {
    visibility: visible;
  }
}

.toolbar {
  height: 70px;
  position: fixed;
  display: none;
  bottom: 0px;
  right: 0px;
  left: 0px;
  padding-right: 125px;
  padding-left: 125px;
  transition: 0.5s;
  align-items: center;
  justify-content: right;
  background: white;
  border-top: 2px solid black;

  ion-button {
    ion-icon {
      margin-right: 5px;
    }
  }
}

.newToolbar {
  height: 88px;
  padding-right: 104px !important;

  doka-button {
    margin-left: 10px;
  }
}

.toolbarPage {
  left: 100px;
}

.menuOpen {
  left: 250px !important;
}

.menuOpenToolbar {
  left: 250px !important;
}

.toolbarOpen {
  display: flex;
}

.textRight {
  position: absolute;
  right: 16px;
}

.yardcamLink {
  padding-right: 10px;
}

.mat-datepicker-popup {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  -moz-transform: translateX(-50%) translateY(-50%) !important;
  -webkit-transform: translateX(-50%) translateY(-50%) !important;
  transform: translateX(-50%) translateY(-50%) !important;
}

.mat-calendar-body-selected {
  background-color: #004586;
}

.error-message {
  color: red !important;
}

ion-modal.modal-default.show-modal:last-of-type {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  @media only screen and (orientation: landscape) {
    --height: auto;
    --width: auto;
    --min-width: 400px;
    --max-height: 69%;
    --max-width: 69%;
  }

  @media only screen and (orientation: portrait) {
    --height: 100%;
    --width: 100%;
  }

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      overflow-y: hidden;
    }
  }
}

ion-popover.auto-width {
  &::part(content) {
    min-width: fit-content;
  }
}

doka-button {
  .content-wrapper {
    user-select: none;
  }
}

.tableLoadingSpinner {
  display: block;
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}

// Fixes for ionic 7
ion-grid {
  margin-inline: unset !important;
}

ion-col {
  font-size: 0.7rem !important;
  text-wrap: initial;
}

ion-searchbar {
  .searchbar-input {
    font-size: 16px !important;
  }

  .searchbar-search-icon {
    height: 21px !important;
    width: 21px !important;
    top: 10px !important;
  }

  .searchbar-clear-icon.sc-ion-searchbar-md {
    width: 1.6rem !important;
  }
}

ion-item {
  text-wrap: nowrap !important;
}

ion-accordion {
  .ion-accordion-toggle-icon {
    position: absolute;
    right: 10px;
  }
}

doka-button {
  cursor: pointer;
}

ion-input {
  --background: #eff0f0;
  --highlight-color: var(--ion-color-primary) !important;
}

ion-select {
  --highlight-color: var(--ion-color-primary);
}

a {
  color: var(--ion-color-primary);
}

h5 {
  font-size: unset !important;
}

doka-spinner {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.infinite-scroll-content {
  text-align: center;
  .infiniteScrollSpinner {
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block !important;
  }
}

.notVisible {
  visibility: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal !important;
  letter-spacing: normal;
}

.printViewContent {
  display: flex;
  flex-flow: column;
  align-items: center;
  background: rgb(82, 86, 89);
}

.previewFooter {
  border-top: 1px solid black;
  padding: 10px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.printViewPage {
  width: 297mm;
  height: 210mm;
  font-size: 11pt !important;
  background: white;
  margin: 20px;

  .print-header {
    display: flex;
    flex-direction: row;
    padding-bottom: 42px;
    padding-top: 42px;
    padding-left: 42px;
    padding-right: 42px;
    justify-content: space-between;
    align-items: center;
    ion-img {
      width: 150px;
    }

    .print-picking,
    .print-order,
    .print-transport-order {
      padding-left: 10px;
      padding-right: 10px;
      span {
        text-wrap: nowrap;
      }
    }
  }

  .print-content {
    padding-left: 42px;
    padding-right: 42px;

    table {
      width: 100%;

      th {
        padding: 5px;
      }

      td {
        padding: 5px;
        border: 1px solid black;
        text-wrap: nowrap;
      }
    }
  }

  .print-footer {
    display: flex;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 42px;
    padding-bottom: 42px;
    justify-content: space-between;

    .companyInfo {
      padding: 5px;
    }

    .customerInfo {
      display: flex;
      flex-flow: row;

      div {
        padding: 5px;
      }
    }
  }
}

.pagePrintFixed {
  position: fixed;
}

.printModalContent::part(scroll) {
  background: rgb(82, 86, 89);
  overflow-y: var(--overflow);
  overscroll-behavior-y: contain;
  overflow-x: var(--overflow);
  overscroll-behavior-x: contain;
}

.articleImageTable {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.itemDetailHeader {
  display: flex;
  align-items: center;

  .progressBar {
    padding-left: 5px;
    text-align: center;
  }
}

.mainTable {
  thead {
    z-index: 11 !important;
  }
}

doka-icon {
  user-select: none;
}

.datePickerModal::part(content) {
  width: fit-content;
  height: fit-content;
}

.shippingBoardOpacity {
  opacity: 0.5;
}

.noWrap {
  .mat-mdc-tooltip-surface {
    overflow-wrap: normal !important;
    max-width: unset !important;
  }
}

// Styles for employee calendar
.fc-daygrid-day-number {
  cursor: default;
  color: black;
  text-decoration: none;
}

.fc-col-header-cell-cushion {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.fc-scroller {
  overflow: auto !important;
}

.fc-event {
  cursor: default !important;
}

.fc-col-header-cell-cushion {
  cursor: default !important;
}

.absenceEntry {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.absenceEntryIcon {
  cursor: pointer;
  font-family: "Material Symbols Outlined";
  font-size: 1.25rem;
  font-weight: 400;
  font-variation-settings: "FILL" 0, "opsz" 20;
}

.cdk-drag-preview {
  overflow: hidden;
}

.secondary-progress-bar {
  .doka-progress-bar-label {
    color: white !important;
  }
  .doka-progress-bar-indicator {
    background-color: var(--ion-color-secondary) !important;
  }
}
